/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Tajawal ExtraLight'), local('Tajawal-ExtraLight'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l_6gHrRpiZtK6GwN9w.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Tajawal ExtraLight'), local('Tajawal-ExtraLight'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l_6gHrFpiZtK6Gw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Tajawal Light'), local('Tajawal-Light'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l5qjHrRpiZtK6GwN9w.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Tajawal Light'), local('Tajawal-Light'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l5qjHrFpiZtK6Gw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Tajawal'), local('Tajawal-Regular'),
    url('/assets/fonts/Iura6YBj_oCad4k1nzSBC5xLhLFw4Q.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Tajawal'), local('Tajawal-Regular'),
    url('/assets/fonts/Iura6YBj_oCad4k1nzGBC5xLhLE.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Tajawal Medium'), local('Tajawal-Medium'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l8KiHrRpiZtK6GwN9w.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Tajawal Medium'), local('Tajawal-Medium'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l8KiHrFpiZtK6Gw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Tajawal Bold'), local('Tajawal-Bold'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l4qkHrRpiZtK6GwN9w.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Tajawal Bold'), local('Tajawal-Bold'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l4qkHrFpiZtK6Gw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Tajawal ExtraBold'), local('Tajawal-ExtraBold'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l5anHrRpiZtK6GwN9w.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Tajawal ExtraBold'), local('Tajawal-ExtraBold'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l5anHrFpiZtK6Gw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Tajawal Black'), local('Tajawal-Black'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l7KmHrRpiZtK6GwN9w.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF,
    U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Tajawal';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Tajawal Black'), local('Tajawal-Black'),
    url('/assets/fonts/Iurf6YBj_oCad4k1l7KmHrFpiZtK6Gw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
