$width: 40px;
$height: 25px;

.checkbox {
  ion-toggle {
    --background: #fbf7ff;
    --background-checked: #fbf7ff;
    --handle-background: var(--ion-color-secondary);
    --handle-background-checked: var(--ion-color-secondary);
    --handle-box-shadow: none;
    --handle-width: 21px;
    --handle-border-radius: 50%;

    width: $width;
    height: $height;
    border: 1px solid var(--ion-color-secondary);
    border-radius: 18px;
  }
}
