.thank-you-page {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    width: 90%;
  }

  .btn{
    background:#2ecc71;
    border:#2ecc71;
  }

  ion-icon {
    font-size: 60px;
  }
}
