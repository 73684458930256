.input {
  position: relative;
  // display: flex;
  width: 100%;

  .error {
    color: var(--ion-color-danger) !important;
    font-size: 13px;
    padding: 3px 0;
    position: absolute;
    min-width: 280px;
  }

  input,
  textarea {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 3px 20px 0;
    height: 58px;
    font-size: 16px;
    background: none;
    border-radius: 5px;
    transition: all 0.15s ease;
    background: var(--ion-color-grey);
    border: 1px solid #e6e6e6;
    // border-radius: 30px;
    padding-top: 6px;

    &:focus {
      outline: none;
    }
  }

  textarea {
    resize: none;
    height: 100px;
    font-size: 15px;
    padding-top: 17px;
    display: block;
  }

  span {
    text-transform: uppercase;
    font-weight: 400;
  }

  &.hasError {
    margin-bottom: 35px !important;

    input,
    textarea {
      border-color: var(--ion-color-danger) !important;
    }
  }
}

.rtl {
  .input {
    input {
      padding-top: 3px;
    }
    :read-only {
      color: rgb(159, 159, 159);
    }
  }
}
