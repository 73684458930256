.tap-pay-page {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    width: 90%;
  }

  // .btn{
  //   background:#2ecc71;
  //   border:#2ecc71;
  // }

  ion-icon {
    font-size: 60px;
  }
  
  .btn-pay{
    background:#2ecc71;
    border:#2ecc71;
  }

  .btn-back-home{
    margin-top:20px;
    background:#f8f8f8;
    border:1px solid #eee;
    color:#333;
  }

  .btn-support{
    margin-top:40px;
    // background:#333;
    // border:#333;
    color:#333;
    background:#f8f8f8;
    border:1px solid #eee;
  }
  .btn-support span{
    font-size: 14px;
  }
  .btn-support p{
    font-size: 20px;
  }
}
