.app-tabs {
  &.ion-page {
    top: initial;
    display: block;
    overflow: initial;
  }

  ion-tabs {
    display: block !important;
    position: relative !important;
    top: initial !important;

    .tabs-inner {
      display: none;
    }

    ion-tab-bar {
      height: 60px;
      bottom: 0;
      position: absolute;
      width: 100%;
      left: 0;
      border-top: 1px solid #eee;
    }

    ion-tab-button {
      padding: 4px 0;
      opacity: 0.5;

      ion-label {
        margin-top: 2px;
        font-size: 12px;
        text-transform: capitalize;
      }

      &.tab-selected {
        opacity: 1;
        ion-icon {
          animation: ease-in 0.4s show-bounce;
        }
      }
    }
  }
}

@keyframes show-bounce {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
