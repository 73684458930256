.fav-button {

  &.loading {
    --background: transparent;
  }

  .add-to-fav{
    background:#f9f9f9;
    width:45px;
    height:45px;
    color:#494949;
    box-shadow: 0 0 4px #ddd;
    font-size: 33px;
    padding: 0;
    padding-top: 6px;
    border:0px;
  }
  .add-to-fav.remove{
    background:#ea5e88;
    color:#f7d7de;
  }

  a {
    text-decoration: none;
  }
  
  .image {
    width: 121px;
    height: 90px;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    margin: auto;

    > div {
      background-size: 20% !important;
      background-color: transparent !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    img.loader {
      width: 20px !important;
      height: 20px !important;
      top: initial !important;
      left: initial !important;
      display: flex;
    }
  }

  .info {
    color: #444;
    padding: 6px 5px;
    height: 60px;
    margin: 0;
    text-align: center;

    .name {
      font-size: 13px;
      margin-bottom: 0;
      text-transform: capitalize;
    }

    p {
      font-size: 11px;
      margin: 0;
    }

    .price {
      font-size: 11px;
      margin-bottom: 0;

      span {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.7px;
      }
    }
  }

  .actions {
    padding: 0 5px 5px;

    $border-color: #ddd;
    $color: #333;
    $height: 32px;

    .btn {
      height: $height;
      padding-top: 2px;
      border-color: $border-color;
      color: $color;

      ion-icon {
        font-size: 20px;
        top: -3px;
      }
    }

    .add-remove {
      border: 1px solid $border-color;
      border-radius: 4px;
      height: $height;
      position: relative;

      .quantity {
        height: 25px;
        font-size: 17px;
        display: inline-flex;
        margin: 2px auto 0;
        padding-top: 5px;
        align-items: center;
        justify-content: center;
        align-content: center;
      }

      button {
        position: absolute;
        top: 0;
        height: 100%;
        width: 30px;
        font-size: 25px;
        background: none;
        padding: 5px;
        outline: 0;

        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
          padding: 2px;
          font-size: 33px;
        }
      }
    }
  }

  &.loading {
    min-height: 180px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #e9e9e9;
    background-image: linear-gradient(
      to right,
      #e9e9e9 0%,
      #dfdfdf 20%,
      #e9e9e9 40%,
      #e9e9e9 100%
    );
    background-size: 800px 104px;
    position: relative;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
