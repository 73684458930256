.store-page {
  ion-content {
    .category-list {
      &.icons {
        margin-top: 20px;
      }

      &.images {
        margin-bottom: 25px;
      }
    }

    .request-products {
      color: #333;
      border-color: #ccc;
      background-color: rgb(248, 248, 248);
    }
  }

  h3 {
    margin-top: 30px;
  }

  .category-list {
    ion-row {
      ion-col {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;

        .img {
          ion-img {
            width: 55px;
            margin: 7px auto;
          }
        }
      }
    }

    &.icons ion-row ion-col .img {
      background: #fff;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.07);
      position: relative;
      margin: auto;
      margin-bottom: 8px;

      ion-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 33px;
        width: 100%;
      }
    }
  }
}
