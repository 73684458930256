
.location-info-modal{
  ion-footer{
    background: #fff;
    padding:0 20px;
  }  

  .input input {
    border-radius: 5px;
  }
}

.long_address-container{
  .long_address-content{
    // font-size: 14px;
    // font-weight: 600;
    // color:#666;
  }
  .long_address-content{
    font-size: 14px;
    font-weight: 600;
    color:#666;
  }
}

.location-page {
  .wrapper,
  .wrapper .map {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .wrapper {
    padding: 10px;

    .map {
      background: #ddd;
    }

    .search-input {
      margin-top: 40px;
      padding: 0 10px;
    }

    .location-mark {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 35px;
      color: #128bd4;
    }

    .actions {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0px 10px 35px;

      .info {
        min-height: 40px;
        margin-bottom: 15px;
        text-align: center;

        .auto-select {
          font-size: 40px;
          position: absolute;
          top: 0;
          right: 10px;
          color: #05c7b7;
        }
      }
    }
  }
}

.enter-label {
  padding: 0 10px;
}
