.order-page {
  > ion-content {
    --padding-top: 0;
  }

  .order-item{
    font-weight: normal;
    border-bottom: 1px solid #eee;
  }
  .order-item .title{
    font-size: 12px;
  }

  h3 {
    color: #aaa;
    text-align: center;
    margin-bottom: 25px;
  }

  .payment-method {
    text-transform: capitalize;
  }
  .loading-icon svg circle {
    stroke : #333 !important;
  }

  .gs-block{
    border-radius: 5px !important;
  }

  .cart-item{
    box-shadow: none !important;
    border:1px solid #eee;
  }

  .cart-item .image img{
    width: 60px;
    height:60px;
  }

  .delivery-status-container{
    padding:30px 20px;
    text-align: center;
  }

  .delivery-status-container img{
    width:180px;
    max-width: 100%;
  }
  .delivery-status-container .body{
    padding-top:15px;
  }
  
}
