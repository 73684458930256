.product-view-page{
  .swiper-slide {
    text-align: center;
  }
  .btn-add-to-cart {
    background: linear-gradient(to left, #E89F71, #FF7777);
  }

  .product-title{
    font-size: 25px;
    font-weight: bold;
  }

  .option-title{
    font-size: 16px;
    font-weight: 600;
  }
  .required-flag{
    background: #eee;
    font-size: 14px;
    font-weight: normal;
    padding:2px 10px;
    border-radius: 5px;
  }

  .add-to-cart-button-container {
    position : fixed !important;
    bottom : 0px;
    border : 0;
    left : 0;
    right : 0;   
    // padding: 15px !important; 
    .add-remove-content{
      // border-radius: 15px;
      background-color: #eee;
    }
    .btn-add-to-cart{
      overflow: hidden;
      border-radius: 50px;
    }
    .btn-add-remove{
      background: #333;
      border-radius: 100px;
      height: 45px;
      width: 45px;
      text-align: center;
      padding: 0;
      padding-top: 10px !important;
    }
    .add-remove{
      margin: 0 auto;
      // width: 200px;
      .quantity{
        font-size: 22px;
        text-align: center;
        margin-top: 13px;
      }
    }
  }
  .btn-return-policy {
    border: 0px;
    padding: 10px;
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    align-items: center; 
    font-size: 10px; 
    font-weight: 400; 
    color: #333333; 
    background: linear-gradient(to left, #E89F7150, #FF777750);
    ion-text {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  ion-icon {
    font-size: 18px;
    font-weight: 400;
  }
  .justify-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
  }
  .action-btn {
    color: '#323232';
    font-size: 18;
  }
  .label {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
  .unit {
    font-size: 9px;
    font-weight: 300;
    color: #333333;
  }
  .seller-name {
    font-size: 14px;
    font-weight: 600;
    color:#E89F71;
    margin-right: 2px;
  }
  .badge {
    font-size: 10px;
    padding-top: 5px;
    border-radius: 0px;
  }
}
