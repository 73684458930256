.product-item-design-2 {

  .product-item-content{
    display: flex;
  }
  
  .product-item-image{
    width: 75px;
    height: 75px;
  }

  .product-item-info{

  }

  .product-price{
    font-size: 14px;
    color: #3199e7;
  }

}

.product-item {

  .product-item-image{
    

  }
  .product-item-image div{
    height: 120px;
    width: 100%;
    background-size: cover !important;
    background-position: center center !important;
  }

  padding: 5px;
  border-radius: 10px;
  background: #fff;
  font-size: 11px;
  display: block;

  &.loading {
    --background: transparent;
  }

  a {
    text-decoration: none;
  }

  .image {
    max-width: 300px;
    // width: 121px;
    // height: 150px;
    width: 100%;
    height: auto;
    padding: 20px 0;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    margin: auto;

    > div {
      background-size: 20% !important;
      background-color: transparent !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    img.loader {
      width: 20px !important;
      height: 20px !important;
      top: initial !important;
      left: initial !important;
      display: flex;
    }
  }

  .info {
    color: #444;
    padding: 6px 5px;
    height: 60px;
    margin: 0;
    text-align: center;

    .name {
      font-size: 14px;
      margin-bottom: 0;
      text-transform: capitalize;
    }

    p {
      font-size: 11px;
      margin: 0;
    }

    .price {
      font-size: 14px;
      margin-bottom: 0;

      span {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.7px;
      }
    }
  }

  .actions {
    margin-right: auto;
    padding: 0 5px 5px;

    $border-color: #ddd;
    $color: #333;
    $height: 32px;

    Button{
      font-size: 12px;
      font-weight: normal;
      padding:0 10px;
      padding-left: 35px;
    }
    // .btn span{
    //   padding:0 5px;
    // }

    .btn {
      border: 1px solid #eee;
      height: $height;
      padding-top: 2px;
      border-color: $border-color;
      color: $color;

      ion-icon {
        font-size: 20px;
        top: -3px;
      }
    }

    .add-remove {
      border: 1px solid $border-color;
      border-radius: 4px;
      height: $height;
      position: relative;
      

      ion-grid{
        padding:0px;
      }

      .quantity {
        width: 100%;
        text-align: center;
        // height: 25px;
        font-size: 17px;
        display: inline-flex;
        // margin: 2px auto 0;
        // padding-top: 5px;
        align-items: center;
        justify-content: center;
        align-content: center;
      }

      .btn-add-remove .trash-icon{
        padding-left: 20px;
        font-size: 13px;
      }

      .btn-add-remove {
        width: 30px;
        border:0px !important;
        padding:0 5px !important;
        margin-top: -4px !important;
        color:#333;
        text-align: center;
        // width: 100%;
        // position: absolute;
        // top: 0;
        // height: 100%;
        // width: 30px;
        font-size: 25px;
        background: none;
        // padding: 5px;
        outline: 0;

        // &:first-child {
        //   // left: 0;
        // }

        // &:last-child {
        //   // right: 0;
        //   // padding: 2px;
        //   font-size: 33px;
        // }
      }
    }
  }

  &.loading {
    min-height: 180px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #e9e9e9;
    background-image: linear-gradient(
      to right,
      #e9e9e9 0%,
      #dfdfdf 20%,
      #e9e9e9 40%,
      #e9e9e9 100%
    );
    background-size: 800px 104px;
    position: relative;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
