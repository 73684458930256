.cart-item {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  background:#fff;
  padding: 5px;
  border: 1px solid rgb(247, 247, 247);
  border-radius: 10px;
  margin-bottom: 5px;
}
.cart-item-img{
  width: 50px;
  height: 50px;
}
.item-name{
  font-weight: 600;
  font-size: 16px;
  margin:0px;
}
.item-options{
  p{
    margin:0px;
    padding:0px;
  }
}
.add-quantity {
  // float: left;
  background-color: #f6f6f6;
  box-shadow: 0 0 15px #f6f6f6;
  width: 90px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
  position: relative;
  height: 25px;
  border-radius: 3px;
  box-shadow: 1px 1px 4px #ccc;
  margin-bottom: 10px;
  margin-left: 10px;

  .hydrated{
    padding:0px;
  }

  .quantity-display{
    padding-top: 1px;
  }
  button {
    background: none;
    color: #333;
    // padding: 5px 2px;
    padding: 0 2px;
    width: 100%;
    text-align: center;
    font-size: 18px !important;
    font-weight: bold;
    position: absolute;
    left: 0;
    outline: 0;

    &:first-child {
      top: 0;
      // padding-top: 6px;
    }

    &:last-child {
      bottom: 0;
      font-size: 25px;
      padding-bottom: 1px;
    }
  }
}
.image {
  width: 120px;
  background:#fff;
  padding: 0 5px;
  margin: 0;
}