.plt-android .products-page{
  .topbar-category-image{
    padding-top: 10px;
  }

  .categories.toolbar-segment{
    height:85px !important;
  }
  .categories ion-segment ion-segment-button{
    // height: 45px !important;
    height:85px !important;
  }
  .toolbar-subcategories ion-segment-button{
    margin-top: 10px;
    flex : unset !important;
  }  
}
.products-page {

  .nav-to-cart{
    padding: 10px;
    position: fixed;
    position: fixed;
    left: 0;
    bottom: 15px;
    right: 0;
    .btn{
      width: 100%;
    }
  }

  // .topbar-categories{

  // }

  //  'paddingTop' : '1px', 'paddingBottom' : '10px'
  
  .topbar-category-image{
    // padding-top: 10px;
    padding-bottom : 4px;
    height: 100%;
    img{
      width: auto;
      max-width: 50px;
      max-height: 100%;
    }
  }

  .topbar-category-name {
    padding-top: 1px;
    padding-bottom: 10px;
  }
  .toolbar-subcategories{

    width: 100%;
    z-index: 13;
    background: #f6f6f6 !important;
    --background: #f6f6f6 !important;
    --padding-bottom: 0;
    ion-segment-button{
      // back
    }
    ::-webkit-scrollbar,
    *::-webkit-scrollbar {
      display: none;
    }

    ion-segment {
      --ripple-color: transparent;

      ion-segment-button {
        --color-checked: transparent;
        --backgroud-checked: transparent;
        height: 30px !important;
        min-height: unset;
        text-transform: capitalize;
        background: #fff;
        color:#333;
        border-radius: 15px;
        margin-inline-start: 5px;
        border:1px solid #ddd;
      }
      ion-segment-button.segment-button-checked{
        background: var(--background-primary) !important;
        color:#fff !important;
        border:0px !important;
      }
    }
  }

  ion-list{
    background-color: #f6f6f6;
  }
  .gs-products-grid{
    padding:0 10px;
  }
  .product-card{
    border:1px solid #eee;
    .product-img{
      min-height: 171px;
      // background-color: #f9f9f9;
    }
    .product-info{
      padding:5px;
    }
    .product-name{
      font-size: 13px;
      font-weight: normal;
      height:40px;
      overflow: hidden;
      margin:10px 0;
    }

    .product-price{
      font-size: 14px;
      font-weight: bold;
      color: #ed8baa;
    }
  }
  

  .search-result-item{
    font-size: 14px;
    font-weight: normal;
    border-bottom: 1px solid #eee;
    padding:12px 20px;
  }
  
  .categories {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;
    width: 100%;
    z-index: 13;
    background: #fff !important;
    --background: #fff !important;
    --padding-bottom: 0;

    ::-webkit-scrollbar,
    *::-webkit-scrollbar {
      display: none;
    }

    ion-segment {
      --ripple-color: transparent;

      ion-segment-button {
        --color-checked: var(--ion-color-tertiary);
        --backgroud-checked: var(--ion-color-tertiary);
        min-height: 39px;
        text-transform: capitalize;
      }
    }
  }

  > ion-content {
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --background: #f6f6f6;
  }

  ion-content.container {
    // --background: var(--ion-color-grey);

    .sub-categories {
      left: 0;
      position: fixed;
      padding: 2px 0 0;
      --background: var(--ion-color-grey);

      ul.items {
        display: flex;
        margin: 0;
        padding: 10px 5px 15px;
        overflow-x: auto;
        justify-content: flex-start;

        li {
          display: inline-flex;
          border-radius: 30px;
          padding: 5px 20px 0;
          margin: 0 5px;
          height: 38px;
          background: #fff;
          border: 1px solid #eee;
          font-size: 15px;
          align-items: center;
          font-weight: 500;
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:last-child {
            margin-right: 15px;
          }

          &:first-child {
            padding: 2px 15px;
          }

          &.active {
            background: var(--ion-color-tertiary);
            border-color: var(--ion-color-tertiary);
            color: #fff;
          }
        }
      }
    }

    .product-list {
      width: 100%;
      padding: 70px 15px 85px;
      --ion-item-background: transparent;

      .add-product {
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #ddd;

        ion-col {
          background: #eee;
          display: flex;
          align-items: center;
          padding: 5px 0;
          height: 120px;
        }

        .text {
          text-align: center;
          font-size: 15px;
          margin: auto;
          width: 130px;
        }

        button {
          background: #fff;
          color: #333;
          margin: auto;
          text-align: center;
          outline: 0;

          &.add-btn {
            font-size: 30px;
            color: #999;
            width: 50px;
            height: 50px;
            padding: 0;
            border-radius: 50%;
            padding-top: 8px;
          }

          &.click-here-btn {
            height: 28px;
            border-radius: 30px;
            padding: 0 15px;
            padding-top: 4px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.filter-modal-container{
  .filter-list-item {
    font-size: 14px;
    padding:8px 0;
    border-bottom:1px solid #f5f5f5;
  }
}

.sort {
  padding: 0 20px;

  ion-label {
    margin-bottom: 15px;
  }
}

.group {
  .uploaded-images {
    --padding-start: 0;
    > .image {
      width: 80px;
      height: 80px;
      border: 2px solid #eee;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      align-items: center;

      > div {
        background: none !important;
      }
    }
  }
}

.rtl {
  .products-page {
    ion-content.container {
      .sub-categories ul.items li:last-child {
        margin-right: 15px;
      }
    }
  }
}


.filter-components{

}