.time-picker {
  .picker {
    margin-bottom: 20px;

    .title {
      text-align: center;
      margin-bottom: 15px;
      font-size: 15px;
      color: #aaa;
    }

    .options {
      white-space: nowrap;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        min-width: 142px;
        display: inline-block;
        margin: 0 4px;

        input[type='radio'] {
          position: absolute;
          opacity: 0;
          top: -99999px;
          right: 0;
          left: 0;

          &:checked + ion-chip {
            border-color: var(--ion-color-secondary);

            ion-label {
              color: var(--ion-color-secondary);
            }
          }
        }
      }

      ion-chip {
        border-color: #ccc;
        height: 46px;
        border-radius: 35px;
        width: 100%;
        padding-left: 18px;
        padding-right: 18px;
        --background: none !important;

        --ripple-color: transparent;

        ion-label {
          font-size: 16px;
          margin: auto;
          padding-top: 4px;
          line-height: 19px;
        }
      }
    }
  }

  .footer {
    padding: 0 15px;
  }
}
