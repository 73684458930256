.wishlist-page {
  ion-content::part(scroll) {
    background-color:#f9f9f92b;
  }
  .wrapper {
    padding-bottom: 75px;

    .totals.gs-block.last {
      box-shadow: 0 0 10px #eee;
      border: 0;
      background:#fff;
    }
  }

  .label {
    margin: 30px 0 15px;

    &:first-child {
      margin-top: 0;
    }

    span {
      color: #aaa;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .promocode {
    ion-item {
      font-size: 13px;
      --padding-start: 15px;
      --padding-end: 0px;
      --padding-top: 5px;
      --padding-bottom: 3px;

      .has-promocode {
        margin-top: 5px;
        display: block;
      }

      button {
        height: 21px;
        font-size: 12px;
        padding: 4px 8px 0;
        line-height: normal;
      }
    }
  }

  .empty-cart {
    text-align: center;
  }
}
