.orders-page {
  > ion-content {
    // --background: var(--ion-color-grey);
    --background:#fff;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 5px;

    .status-img{
      width: 60px;
    }
    .order-card-info{
      display: flex;
    }
    .status-info {
      margin-right:5px;
      padding-top:8px;
    }
    .order-card-info span {
      font-size: 14px;
    }
    .order-card-info .order-date{
      color:#888 !important;
      font-size: 14px;
      font-weight: normal;
    }
    
    ion-list {
      ion-item {
        --padding-top: 8px;
        --padding-bottom: 8px;
        border-bottom: 1px solid #ddd;

        ion-row {
          ion-col {
            padding: 0;
            padding-top: 3px;
            margin-bottom: -4px;
            display: grid;
            align-items: center;
            text-align: center;

            &.id-date {
              text-align: left;
            }

            &.price {
              font-size: 15px;
              font-weight: 600;
              text-align: right;
            }

            ion-chip {
              height: 20px;
              display: inline-block;
              width: fit-content;
              padding: 6px 10px 0;
              margin: -7px auto 0;
              font-size: 13px;

              ion-label {
                --color: #fff !important;
              }

              &.success {
                background: var(--ion-color-success);
              }

              &.yello {
                background: #f1c40f;
              }

              &.dark {
                background: var(--ion-color-dark);
              }

              &.warning {
                background: var(--ion-color-warning);
              }

              &.info {
                background: var(--ion-color-info);
              }

              &.danger {
                background: var(--ion-color-danger);
              }

              &.grey {
                background: var(--ion-color-grey);
              }
            }
          }
        }

        h3 {
          font-weight: 600;
        }

        p {
          padding-bottom: 0;
        }
      }
    }
  }

  ion-segment {
    --ripple-color: transparent;

    ion-segment-button {
      --color-checked: #333 !important;
    }
  }
}

.rtl {
  .orders-page {
    > ion-content ion-list ion-item {
      ion-row ion-col {
        &.id-date {
          text-align: right;
        }

        &.price {
          text-align: left;
        }
      }

      &::part(detail-icon) {
        transform: rotate(-180deg);
      }
    }
  }
}
