.category-list {
  ion-row {
    ion-col {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      font-size: 12px;

      .name{
        max-width:90px;
        margin:0 auto;
      }

      .img {
        img {
          width: 55px;
          margin: 2px auto;
        }

        > div {
          background: none !important;
          background: none !important;
          width: inherit !important;
          height: auto !important;

          img {
            width: 55px;
            margin: 2px auto;
          }

          img[src^='data:image/gif'] {
            width: 15px !important;
            height: 15px !important;
            left: 0 !important;
            top: inherit !important;
          }
        }
      }
    }
  }

  &.icons ion-row ion-col .img {
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.07);
    position: relative;
    margin: auto;
    margin-bottom: 8px;

    ion-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 33px;
      width: 100%;
    }
  }
}
