.banner {
  .img,
  ion-slides .img {
    height: 80px;
    background-color: #333;
    background-position: center;
    background-size: 112%;
    border-radius: 15px;
  }

  ion-slides {
    height: 150px;
    width: 100%;
    border-radius: 20px;
    --bullet-background: #eee;
    --bullet-background-active: #fff;
  }

  a {
    display: contents;
  }

  ion-col {
    padding: 10px;
  }

  &.slide {
    ion-slides .img {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}
