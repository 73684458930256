.billing-page {
  > ion-content {
    --background: var(--ion-color-grey);
    --padding-top: 0;
  }

  h3 {
    color: #aaa;
    text-align: center;
    margin-bottom: 25px;
  }

  .payment-method {
    text-transform: capitalize;
  }
  .loading-icon svg circle {
    stroke : #333 !important;
  }
}
