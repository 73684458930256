.header {
  ion-title {
    font-weight: 500;
    transform: translateX(0px);
    animation: left 0.5s;
    animation-delay: 0.06s;
    margin-top: 3px;
  }

  ion-toolbar .toolbar-background{
    background: linear-gradient(90deg, rgba(237,138,171,1) 0%, rgba(245,193,158,1) 100%) !important;
  }

  ion-button.back-button {
    font-size: 20px;
  }

  ion-badge {
    padding: 5px;
    margin-left: -10px;
    margin-top: -14px;
    z-index: 1;
    border-radius: 20px;
    min-width: 17px;
    height: 17px;
    font-size: 11px;
    text-align: center;
    background: #37314b !important;
  }
}

@keyframes right {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

.rtl {
  .header {
    ion-title {
      font-weight: bold;
    }

    ion-button.back-button {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    ion-badge {
      margin-left: inherit;
      margin-right: -10px;
    }
  }
}
