.verify-page {
  
  .resend-code {
    text-align: center;

    a {
      outline: 0;
    }
  }
}
