@import 'variables.scss';
@import 'font.scss';

body {
  color: var(--ion-color-primary);
  line-height: 23px;
}

.modal-back-drop {
  background: rgba(255, 254, 254, 0);
  height: 100vh;
  width: 100vw;
  position: inherit;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.update-modal {
  height: 400px;
  width: 360px;
  background: rgb(255, 255, 255);
  border-radius: 15px;
  margin-top: 150px;
  top: 0px;
  position: fixed;
  z-index: 100;
  // align-self: center;
  box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.text-primary {
  color: var(--primary) !important;
}
.fs-16 {
  font-size: 1rem !important;
}
.fw-600 {
  font-weight: 600 !important;
}

.no-ripple {
  --ripple-color: transparent;
}

.chip-outline.ion-activated,
ion-ripple-effect {
  background: none;
}

*::-webkit-scrollbar {
  display: none;
}

.slide-routes {
  > .item {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 560px;
  font-size: 64px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: rgba(100, 0, 255, 0.8);
}

$raduis: 20px;

.gs-block {
  border: 1px solid #ebebeb;
  border-radius: $raduis;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;

  > div {
    padding: 3px 5px;
    font-size: 14px;
    border-bottom: 1px solid #ebebeb;

    ion-item {
      --background: none;
      --padding-start: 20px;
      --padding-end: 5px;
      --padding-bottom: 7px;
      --padding-top: 10px;
      --min-height: auto;
    }

    .image-loader {
      .item {
        $size: 40px;
        margin: 5px;
        width: $size !important;
        height: $size !important;
        border-radius: 50%;
        border: 2px solid #eee;
        overflow: hidden;
        display: flex !important;
        float: left;
        align-items: center;
        padding: 3px;

        > div {
          background-color: transparent !important;
        }
      }

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .p-item {
      padding: 2px 15px 0px;
      font-size: 11px;
      background: var(--ion-color-grey);
      display: inline-block;
      border-radius: 15px;
      margin: 3px;
      font-weight: 500;
    }

    .align-right {
      text-align: right;
    }

    &:last-child {
      border-bottom: 0;
    }

    ion-chip {
      height: 23px;
      overflow: initial;

      ion-icon {
        font-size: 14px;
        color: rgb(81, 103, 136);
        margin-right: 5px;
      }

      ion-label {
        font-size: 12px !important;
        margin-top: 5px;
        line-height: 20px;
      }
    }
  }

  &.in-stock,
  &.out-of-stock {
    > div {
      padding: 2px;
    }
  }

  &.totals {
    ion-item {
      font-weight: 500;
      p {
        padding: 0;
        margin: 0;
        margin-bottom: 7px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:first-child {
        p {
          font-size: 14px;
        }
      }
    }
  }

  &.first > div:first-child {
    background: var(--ion-color-grey);
  }

  &.last > div:last-child {
    background: var(--ion-color-grey);
  }

  &.inline {
    display: flex;

    > div {
      border-radius: 0px;
      display: inline-block;
    }

    > div:first-child {
      display: grid;
      font-weight: 600;
      align-content: center;
      justify-content: center;
      border: 0;
      padding: 8px 8px 5px;
      border-right: 1px solid var(--ion-color-light);
      min-width: 30px;
    }
  }
}

.form-control {
  margin-bottom: 25px;
}

.group {
  padding: 0 25px;

  .form-control {
    margin-bottom: 15px;
  }
}

.hidden-input {
  opacity: 0;
  z-index: -1;
  position: absolute;
  left: -99999px;
}

.gs-upload-btn {
  --padding-top: 0;
  --padding-start: 0;

  button {
    padding: 10px 15px;
    border-radius: 15px;
    color: #aaa;
    background: var(--ion-color-light);
    outline: 0;

    ion-icon {
      font-size: 20px;
    }
  }
}

$phone-number-radius: 30px;

.image-loader {
  > div {
    background-color: transparent !important;
  }
}

.gs-search {
  ion-toolbar {
    --ion-safe-area-top: 25px;
    padding: 10px 5px;

    ion-searchbar {
      .searchbar-input-container {
        input {
          padding: 10px 15px 5px;
        }
      }

      .searchbar-cancel-button {
        right: 5px;
        left: inherit;
      }

      .searchbar-clear-button {
        display: none !important;
      }
    }
  }

  ion-content {
    --background: var(--ion-color-grey);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
  }
}

.btn:active{
  color : #fff !important;
}
.item.next-exit,
.item.next-enter,
.item.back-exit,
.item.back-enter {
  .hide-if-ee {
    display: none;
  }
}

.gs-products-grid {
  padding: 0;
  margin-bottom: 5px;

  ion-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.alert {
  width: 100%;
  display: inline-flex;
  border-radius: 25px;
  min-height: 43px;
  background: #eee;
  color: #fff;
  padding: 4px 15px 0;
  align-items: center;
  font-size: 15px;

  &.no-raduis {
    border-radius: 3px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.alert-danger {
    background-color: var(--ion-color-danger);
  }

  &.alert-info {
    background-color: var(--ion-color-tertiary);
  }
}

.gs-loading {
  .spinner-lines {
    margin-bottom: -8px;
  }
}

.tag-input {
  position: relative;

  > div {
    position: relative;
    width: 100%;
    margin-top: 25px;
    font-family: inherit;
    box-shadow: none;
    transform: none;
    left: 0;
    top: 0;

    input::-webkit-input-placeholder {
      font-family: inherit;
      font-weight: 400;
      font-style: normal;
    }

    span {
      padding: 5px 5px 1px 8px;
      font-size: 14px;
    }
  }
}

.ltr {
  font-weight: 500;
}

.ltr .mega-float-left{
  float:left;
}

.ltr .mega-float-right{
  float:right;
}


.mega-place-row{
  width: 100%;
  overflow: hidden;
}

.ltr {
  .mega-float-left {
    float:left;
  }
  
 .mega-float-right{
    float:right;
  }
}

.rtl {
  .mega-float-left {
    float:right;
  }
  
 .mega-float-right{
    float:left;
  }
}

.ltr {
  .mega-place-start {
    float:left;
  }
  
 .mega-place-end{
    float:right;
  }
}

.rtl {
  .mega-place-start {
    float:right;
  }
  
 .mega-place-end{
    float:left;
  }
}

.rtl {
  .mega-float- {
    float:right;
  }
  
 .mega-float-right{
    float:right;
  }
}

.rtl{
  .float-start{
    float:left !important;
  }
  .float-end{
    float:right !important;
  }
}


.ltr{
  .float-start{
    float:left !important;
  }
  .float-end{
    float:right !important;
  }
}


// ### SCC for RTL Direction ###
.rtl {
  direction: rtl;
  font-weight: 600;

  .gs-block {
    &.inline > div:first-child {
      border-radius: 0 $raduis $raduis 0;
    }

    > div .image-loader .item {
      float: right;
    }
  }

  .phone-number {
    > .select {
      direction: ltr;
      
      .label:read-only{
        font-size: 14px;
        color: #aaa;
      }

      .input {
        input {
          // border-top-left-radius: $phone-number-radius;
          // border-bottom-left-radius: $phone-number-radius;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    > .input {
      input {
        // border-top-right-radius: $phone-number-radius;
        // border-right: $phone-number-radius;
        // border-bottom-right-radius: $phone-number-radius;
        border-top-left-radius: 0;
        border-left: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .gs-search {
    .searchbar-cancel-button {
      right: inherit;
      left: 5px;
    }
  }
}

.input{
  font-weight: 500;
}

.item-email input{
  direction: ltr;
  // text-align: right;
}
.ltr {
  .item-email input::placeholder{
    direction: ltr;
    text-align: left;
  }
}
.rtl {
  .item-email input::placeholder{
    direction: rtl;
    text-align: right;
  }
}

.item-phone input{
  direction: ltr;
  // text-align: right;
}
.ltr {
  .item-phone input::placeholder{
    direction: ltr;
    text-align: left;
  }
}
.rtl {
  .item-phone input::placeholder{
    direction: rtl;
    text-align: right;
  }
}

.card-loading {
  --background: transparent;
  min-height: 180px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e9e9e9;
  background-image: linear-gradient(
    to right,
    #e9e9e9 0%,
    #dfdfdf 20%,
    #e9e9e9 40%,
    #e9e9e9 100%
  );
  background-size: 800px 104px;
  position: relative;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
