.btn {
  border: 0px;
  background: var(--ion-color-secondary);
  font-family: inherit;
  display: inline-block;
  color: #fff;
  height: 45px;
  font-size: 20px;
  border-radius: 4px;
  padding: 6px 20px 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  line-height: 30px;
  font-weight: inherit;
  position: relative;

  &.raduis {
    border-radius: 5px;
  }

  &.rounded {
    border-radius: 30px;
  }

  &.large {
    height: 60px;
    padding: 5px 20px 0;
    min-height: 60px;
  }

  &.small {
    height: 38px;
    padding: 4px 20px 0;
    font-size: 15px;
  }

  &.full {
    width: 100%;
  }

  &.has-icon {
    padding-right: 45px;

    &.small {
      padding-right: 35px;
    }

    ion-icon {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &.shadow {
    box-shadow: 0px 5px 20.16px 3.84px lighten(#000, 87%);
  }

  &.secandary {
    background: var(--ion-color-secondary-shade);
    border-color: var(--ion-color-secondary-shade);
  }

  &.tertiary {
    background: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
  }

  &.transparent {
    background: transparent;
    border-color: transparent;
    color: var(--ion-color-primary);
  }

  &.outline {
    background: none;
    color: var(--ion-color-secondary);
    border-color: var(--ion-color-secondary);
    box-shadow: none;
  }

  &.outline.secandary {
    color: var(--ion-color-secandary);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: none;

    span {
      opacity: 0.7;
    }
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[disabled] {
    opacity: 0.9;
  }
}

.rtl {
  .btn {
    &.has-icon {
      padding-right: 20px;
      padding-left: 45px;

      &.small {
        padding-right: 20px;
        padding-left: 35px;
      }

      ion-icon {
        right: initial;
        left: 10px;
      }
    }
  }
}
