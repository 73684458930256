
.search-term-results{
  position: absolute;
  color: #333;
  background-color: #fff;
  color: #333;
  display: none;
  // top: 100px;
  left: 0;
  right: 0;
  // bottom: 0;
  z-index: 100;
}
.search-term-results.active{
  display: block;
}
.term-item{
  border-bottom: 1px solid #eee;
  padding : 14px 20px;
  font-size: 13px;
}

.login-modal {
  overflow: auto;
  ion-item{
    margin-bottom:20px;
  }
  .verification-code-item input::placeholder {
    text-align:right;
  }
  > ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 85px;
    --background : #fff;
  }

  .need-help {
    padding: 4px 15px 0;
    background: #eee;
    border-radius: 20px;
    font-size: 12px;
    height: 27px;
    display: flex;
    align-items: center;
  }

  .deliver-to {
    padding-left: 25px;

    a {
      position: relative;
      text-decoration: none;

      span {
        font-size: 13px;
        top: -2px;
        color: #aaa;
        margin: 0 5px;
      }

      button {
        background: var(--ion-color-grey);
        height: 28px;
        padding: 0 12px;
        border-radius: 25px;
        padding-top: 5px;
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 16px;
        outline: 0;

        ion-icon {
          float: right;
          margin-right: -2px;
          margin-top: -4px;
          margin-left: 6px;
          font-size: 18px;
        }
      }
    }
  }

  &.inline {
    padding-bottom: 20px;

    ion-header ion-toolbar {
      border-bottom: 1px solid #f1f1f1;
      box-shadow: 0px 10px 0px 0px white;
      padding-bottom: 10px;
    }
  }

  &.has-no-tabs .ion-page.page-container {
    padding-bottom: 0;
  }
}

.rtl {
  .page {
    .deliver-to {
      padding-left: 90px;
      padding-right: 25px;
    }
  }
}
