.auth {
  .content {
    padding: 20px 15px;

    .logo {
      margin-bottom: 25px;

      ion-img {
        width: 90px;
        margin: auto;
      }
    }

    .head {
      // text-align: center;
      h1 {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
      margin-bottom: 25px;
    }
  }
}
