html{
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}
.cart-page {
  ion-content::part(scroll) {
    background-color: #f0f4fd !important;
  }

  // .cart

  .btn-select-location{
    font-size: 12px;
    height: 25px;
    padding: 0 12px;
    background: #feb089;
    color: #333;
  }

  .btn-cart-disabled{
    background: #f8f8f8;
  }

  ion-content.cart-empty::part(scroll) {
    background-color:#fff;
  }

  ion-content{
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 0px;
    // --padding-bottom: 85px;
  }

  ion-content .wrapper{
    padding:0 20px;
  }

  .selected-location-header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;

    // font-size: 12px;
    .header-title{

    }
    .btn-change-location{
      font-weight: 600;
      color:#333;
      font-size: 13px;
      float:left
    }
  }


  .selected-location-content{
    border:1px solid #eee;
    border-radius: 5px;
    padding:10px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    .location-title{
      font-size: 14px;
      padding-top:4px;
      padding-bottom: 8px;
    }
    .location-phone{
      // font-
      color:#888;
    }
  }
  
  .d-none{
    display: none;
  }

  ._wrapper{
    padding-top:20px;
    background-color:#fff;
  }

  .cart-summary{
    padding-top: 20px !important;
    background: #f0f4fd !important;
    bottom:0%;
  }
  .cart-summary-item{
    font-weight: normal;
  }
  .cart-summary-item span{
    // text-align: left;
    float:left;
  }
  .cart-summary-item.grand_total{
    font-weight: bold;
  }

  .coupon-container ion-grid{
    padding:0px;
  }
  .coupon-container .btn-container{
    text-align: center;
    padding:3px 0;
  }

  .coupon-container .btn{
    background: transparent !important;
    // height: 100%;
    height: unset;
    color:#333;
    font-size: 16px;
    border-radius: 0px;
    border-right: 2px solid #ddd;
    border-left: 0px solid #ddd;
  }

  .cart-summary .coupon-container {
    background: rgb(255, 255, 255);
    box-shadow: 0 0 5px #ddd;
    border-radius: 2px;
    padding:0;
    height: unset;
    margin-bottom: 10px;;
  }
  .cart-summary .coupon-control{
    background: transparent;
  }
  .cart-summary .coupon-control input{
    background: transparent;
    border-radius: 0px;
    padding:10px 10px;
    height: unset;
    border:0px;
  }
  .cart-summary p{
    font-size: 13px;
    padding: 4px 0;
    margin: 0;
  }

  ion-footer{
    // bottom: 61px;
    // display: none;
    border-top:1px solid #ddd;
    padding:10px;
    background:#fff;
  }

  .wrapper {
    // padding-bottom: 75px;

    .totals.gs-block.last {
      box-shadow: 0 0 10px #eee;
      border: 0;
      background:#fff;
    }
  }

  .label {
    margin: 30px 0 4px 0;

    &:first-child {
      margin-top: 0;
    }

    span {
      color: #aaa;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .promocode {
    ion-item {
      font-size: 13px;
      --padding-start: 15px;
      --padding-end: 0px;
      --padding-top: 5px;
      --padding-bottom: 3px;

      .has-promocode {
        margin-top: 5px;
        display: block;
      }

      button {
        height: 21px;
        font-size: 12px;
        padding: 4px 8px 0;
        line-height: normal;
      }

    }
  }
  
  .btn-text {
    background:none;
    color: rgb(240, 48, 48);
    text-shadow: none;
    font-size: small;
  }

  .empty-cart {
    text-align: center;
  }
}

.ltr .coupon-container .btn{
  text-align: center;
  border-right: 0px solid #ddd;
  border-left: 2px solid #ddd;
}

.selected-address{
  display: flex;
}
.selected-address .nav-icon{
  font-size: 30px;
  padding-top: 6px;
}

.ltr .selected-address .nav-icon{
  margin-left: auto;
}
.rtl .selected-address .nav-icon{
  margin-left: auto;
}