.checkout-page {
  
  .checkout-section-title {
    padding:10px 0;
  }
  .contact-info .input {
    margin-bottom: 10px;
  }
  .contact-info input{
    height:45px;
    background-color: #fff;
    border:1px solid #eee;
  }
  .checkout-page-wrapper {
    .form-control > .label {
      margin-bottom: 5px;
      font-weight: 500;
    }
  }

  .shipments {
    .gs-block {
      margin-top: 5px;
      padding: 3px 5px;
    }
  }
}

@media (max-width: 355px) {
  .checkout-page {
    .form-control {
      .radio {
        ion-col {
          width: 100% !important;
          margin-bottom: 10px;

          ion-item.radio-wrapper {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.rtl .nav-icon{
  margin-right: auto;
  margin-left: unset !important;
  transform: rotate(180deg);
}