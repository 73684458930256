.page {
  overflow: auto;
  background : #fff;
  > ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 85px;
    // border-top-left-radius: 15px !important;
    // border-top-right-radius: 15px !important;
    overflow:hidden;
  }

  ion-content::part(background){
    // border-top-left-radius: 15px !important;
    // border-top-right-radius: 15px !important;
    overflow:hidden;
  }

  ion-toolbar {
    --background : var(--ion-color-secondary);
    background-size: 200% 100%;

    ion-title{
      color:#fff;
    }

    // ion-button::part(native){
    //   var(--ion-toolbar-color, var(--color))
    // }
  }



  .need-help {
    padding: 4px 15px 0;
    background: #eee;
    border-radius: 20px;
    font-size: 12px;
    height: 27px;
    display: flex;
    align-items: center;
  }

  .searchbar-input-container input{
    color:#fff;
  }

  .deliver-to {
    padding-left: 25px;

    a {
      position: relative;
      text-decoration: none;

      span {
        font-size: 13px;
        top: -2px;
        color: #aaa;
        margin: 0 5px;
      }

      button {
        background: var(--ion-color-grey);
        height: 28px;
        padding: 0 12px;
        border-radius: 25px;
        padding-top: 5px;
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 16px;
        outline: 0;

        ion-icon {
          float: right;
          margin-right: -2px;
          margin-top: -4px;
          margin-left: 6px;
          font-size: 18px;
        }
      }
    }
  }

  &.inline {
    padding-bottom: 20px;

    ion-header ion-toolbar {
      border-bottom: 1px solid #f1f1f1;
      box-shadow: 0px 10px 0px 0px white;
      padding-bottom: 10px;
    }
  }

  &.has-no-tabs .ion-page.page-container {
    padding-bottom: 0;
  }
}

.rtl {
  .page {
    .deliver-to {
      padding-left: 90px;
      padding-right: 25px;
    }
  }
}
.search-query-modal{
  ion-content{
    --padding-top : 0px !important;
    --padding-bottom : 0px !important;
    --padding-start : 0px !important;
    --padding-end : 0px !important;
  }
  .search-result{
    background-color: #fff;
  }
  .search-result-item{
    font-size: 14px;
    font-weight: normal;
    padding:10px 15px;
    border-bottom:1px solid #eee;
  }
}
