.splash-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .gif-container{
    padding : 170px 20px;
    text-align: center;
  }
  .gif-container img {
    width: 250px;
    height:auto;
  }
  
  .skip-button-container{
    // display: none;
    background-color: #332e40 !important;
    padding:20px;
    padding-bottom: 50px;
    color:#fff
  }
  .skip-button-container .btn-skip {
    background: #3c3748;
    color: #fff;
    text-align: center;
    padding:20px 0;
    border-radius: 5px;
  }

  .header {
    height: 60px;
    padding: 15px 20px;

    img {
      width: 110px;
    }

    button {
      float: right;
      font-size: 16px;
      padding-right: 5px;
      padding-left: 5px;
      font-weight: bold;
    }
    .later button {
      background:#eee !important;
      border-color:#eee !important;
      color:#333;
    }
  }

  ion-content {
    --background: #332e40 !important;

    .content {
      text-align: center;
      padding-top: 30px;
      padding-right:20px;
      padding-left:20px;
      display: block;
      padding-bottom: 13px;
    }

    .content .img img{
      width:130px;
    }
    .content .title{
      padding:20px 0;
      font-size:24px;
    }
    .content .description{
      font-weight: 400;
      color: #333;
      padding-bottom:20px;
    }
    .content .button-container{

    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 15px 20px;
      z-index: 11;

      button:last-child {
        float: right;
      }
    }
  }
}

.rtl {
  .welcome-page {
    .header {
      button {
        float: left;
      }
    }

    button {
      ion-icon {
        transform: rotate(-180deg);
      }
    }

    .footer {
      button {
        ion-icon {
          transform: rotate(-180deg);
        }
      }

      button:last-child {
        float: left;
      }
    }
  }
}

@media (max-width: 345px) {
  .welcome-page ion-content .content .swiper-pagination {
    display: none;
  }
}
