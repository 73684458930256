.profile-page {
  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }
}

.rtl {
  .profile-page {
    ion-header {
      ion-icon.ion-color-danger {
        transform: rotate(-180deg);
      }
    }
  }
}
