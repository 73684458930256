.select {
  position: relative;

  .label {
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  .select-icon {
    width: 12px;
    height: 18px;
    position: absolute;
    opacity: 0.33;
    top: 21px;
    right: 31px;

    .select-icon-inner {
      left: 5px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      width: 0px;
      height: 0px;
      border-top: 5px solid;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      color: currentcolor;
      pointer-events: none;
    }
  }

  ion-select {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 20px 26px;
  }
}

.select-picker {
  .picker-wrapper {
    display: flex;
    flex-direction: column-reverse;
    overflow: initial;
    contain: initial;
  }

  .picker-toolbar {
    height: 80px;
    border: 0;
    padding: 10px 20px;
    contain: initial;

    .picker-toolbar-button {
      flex: none;
      width: 100%;

      .picker-button {
        &.btn {
          border: 1px solid var(--ion-color-secondary);
          background: var(--ion-color-secondary);
          font-family: inherit;
          color: #fff;
          height: 48px;
          font-size: 18px;
          padding: 3px 20px 0;
          width: 100%;
          border-radius: 30px;
        }
      }
    }
  }

  &.has-title {
    .picker-toolbar .picker-toolbar-button:first-child {
      position: absolute;
      top: -40px;
      left: 0;
      background: #fff;
      border-radius: 30px 30px 0 0;
      padding: 20px;

      button {
        font-size: 23px;
        margin: auto;
        display: block;
      }
    }
  }
}

.rtl {
  .select {
    .label {
      left: initial;
      right: 0;
      top: 3px;
      font-weight: 600;
    }

    .select-icon {
      right: initial;
      left: 31px;

      .select-icon-inner {
        left: initial;
        right: 5px;
      }
    }
  }

  .select-picker.has-title .picker-toolbar .picker-toolbar-button:first-child {
    left: initial;
    right: 0;
  }
}
