.collection-page {
  background: #fff;

  .categories {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;
    width: 100%;
    z-index: 13;
    background: #fff;
    --padding-bottom: 0;

    ::-webkit-scrollbar,
    *::-webkit-scrollbar {
      display: none;
    }

    ion-segment {
      --ripple-color: transparent;

      ion-segment-button {
        --color-checked: var(--ion-color-tertiary);
        --backgroud-checked: var(--ion-color-tertiary);
        min-height: 39px;
        text-transform: capitalize;
      }
    }
  }

  > ion-content {
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-content.container {
    // --background: var(--ion-color-grey);
    --background: #fff;

    .sub-categories {
      left: 0;
      position: fixed;
      padding: 2px 0 0;
      --background: var(--ion-color-grey);

      ul.items {
        display: flex;
        margin: 0;
        padding: 10px 5px 15px;
        overflow-x: auto;
        justify-content: flex-start;

        li {
          display: inline-flex;
          border-radius: 30px;
          padding: 5px 20px 0;
          margin: 0 5px;
          height: 38px;
          background: #fff;
          border: 1px solid #eee;
          font-size: 15px;
          align-items: center;
          font-weight: 500;
          max-width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:last-child {
            margin-right: 15px;
          }

          &:first-child {
            padding: 2px 15px;
          }

          &.active {
            background: var(--ion-color-tertiary);
            border-color: var(--ion-color-tertiary);
            color: #fff;
          }
        }
      }
    }

    .product-list {
      width: 100%;
      padding: 70px 15px 85px;
      --ion-item-background: transparent;

      .add-product {
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #ddd;

        ion-col {
          background: #eee;
          display: flex;
          align-items: center;
          padding: 5px 0;
          height: 120px;
        }

        .text {
          text-align: center;
          font-size: 15px;
          margin: auto;
          width: 130px;
        }

        button {
          background: #fff;
          color: #333;
          margin: auto;
          text-align: center;
          outline: 0;

          &.add-btn {
            font-size: 30px;
            color: #999;
            width: 50px;
            height: 50px;
            padding: 0;
            border-radius: 50%;
            padding-top: 8px;
          }

          &.click-here-btn {
            height: 28px;
            border-radius: 30px;
            padding: 0 15px;
            padding-top: 4px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.sort {
  padding: 0 20px;

  ion-label {
    margin-bottom: 15px;
  }
}

.group {
  .uploaded-images {
    --padding-start: 0;
    > .image {
      width: 80px;
      height: 80px;
      border: 2px solid #eee;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      align-items: center;

      > div {
        background: none !important;
      }
    }
  }
}

.rtl {
  .collection-page {
    ion-content.container {
      .sub-categories ul.items li:last-child {
        margin-right: 15px;
      }
    }
  }
}
