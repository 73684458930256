:root {
  /** primary **/
  --ion-color-primary: #333;
  --ion-color-primary-rgb: 51, 51, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #444;
  --ion-color-primary-tint: #555;
  /* 
    primary colors : 
    #99d319
    #3d9933
  */
  /** secondary **/
  --ion-color-secondary: #ff0000;
  --ion-color-secondary-rgb: 70, 56, 175;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #48ad2d;
  --ion-color-secondary-tint: #48ad2d;

  /** tertiary **/
  --ion-color-tertiary: #7ed6df;
  --ion-color-tertiary-rgb: 245, 187, 43;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7ed6df;
  --ion-color-tertiary-tint: #7ed6df;

  /** success **/
  --ion-color-success: #2ecc71;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** info **/
  --ion-color-info: #09a1ff;
  --ion-color-info-rgb: 255, 196, 9;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #09a1ff;
  --ion-color-info-tint: #09a1ff;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** grey **/
  --ion-color-grey: #f8f8f8;
  --ion-color-grey-rgb: 248, 248, 248;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --background-primary : #333;
  --background: #fff;
  --ion-background-color: #fff;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  --ion-font-family: 'Tajawal', BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
}
