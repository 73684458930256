.product-page {
  .wrapper {
    padding-bottom: 25px;
  }

  .ion-page.page-container {
    padding-bottom: 0;
  }

  ion-toolbar {
    ion-buttons:last-child {
      padding-right: 5px;

      ion-button {
        --padding-start: 0px;
        --padding-end: 0px;

        ion-icon {
          color: #ccc;
        }
      }
    }
  }

  .image {
    min-height: 150px;
    text-align: center;
  }

  .info {
    margin-bottom: 30px;
    text-transform: capitalize;

    p {
      color: #aaa;
      margin: 0;
      padding: 0;
    }
  }

  .add-to-cart {
    display: flex;
    align-items: center;
    align-content: center;

    button {
      background: #7ed6df;
      border: #7ed6df;
      display: inline-block;
      min-width: 160px;
      margin-bottom: 8px;
    }

    .price {
      display: inline-block;
      font-size: 25px;
      height: 40px;
      margin-left: 15px;

      span {
        font-weight: 600;
      }
    }
  }

  .quantity {
    .action {
      border: 1px solid #eee;
      border-radius: 30px;
      min-width: 150px;
      width: fit-content;
      height: 45px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 6px;
      position: relative;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 25px;

      button {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        font-size: 23px;
        font-weight: 900;
        background: none;
        padding: 0 10px;
        padding-top: 4px;
        outline: 0;

        &:first-child {
          left: 0px;
        }

        &:last-child {
          right: 0px;
          font-size: 30px;
        }
      }
    }

    .prices {
      ion-row {
        margin-left: -5px;
        margin-right: -5px;

        .block {
          background: var(--ion-color-grey);
          padding: 10px;
          border-radius: 15px;

          label {
            display: block;
            font-size: 13px;
            color: #777;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

ion-fab {
  animation: show 1s;
  right: 20px;

  ion-badge {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    min-width: 21px;
    padding: 7px 4px;
    line-height: 12px;
    height: 20px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rtl {
  .product-page {
    ion-toolbar {
      ion-buttons:last-child {
        padding-right: inherit;
        padding-left: 5px;
      }
    }

    .add-to-cart {
      .price {
        margin-left: inherit;
        margin-right: 15px;
      }
    }
  }

  ion-fab {
    right: inherit;
    left: 20px;
  }
}
