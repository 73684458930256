.options-container{
  .option-title{
    font-size: 16px;
    font-weight: 600;
    padding:10px;
  }
  .required-flag{
    background: #eee;
    font-size: 14px;
    font-weight: normal;
    padding:2px 10px;
    border-radius: 5px;
    float: right !important;
  }

  .option-price{
    margin-left: 15px;
    float:right;
  }

}

