.radio {
  ion-row {
    margin-left: -5px;
    margin-right: -5px;
  }

  ion-col {
    padding-bottom: 0;
    padding-top: 0;
  }

  ion-radio-group {
    width: 100%;
    display: inherit;

    ion-item.radio-wrapper {
      --background: none;
      --background-activated: none;

      position: relative;
      border: 1px solid var(--ion-color-light);
      border-radius: 30px;
      grid-area: none !important;
      height: 40px;
      width: 100%;
      display: inline-flex;
      background: none !important;
      min-height: 100%;

      &.item-radio-checked {
        border-color: var(--ion-color-secondary);

        &::part(native) {
          background: rgba(var(--ion-color-secondary-rgb), 0.02) !important;
        }
      }

      --padding-start: 8px;

      .radio-label {
        padding-left: 35px;
        top: 2px;
        padding-top: 0;
        margin: 0;
        height: auto;
        padding-bottom: 0;
        display: flex;
        bottom: 0;
        align-items: center;
        position: absolute;
        width: 100%;
        font-size: 13px;
        background: none;
        height: 100%;
      }

      ion-radio {
        margin-left: 3px;
        bottom: 0;
        top: -1px;
        align-items: center;
        display: flex;

        &::part(container) {
          border-color: #e0e0e0 !important;
        }

        --color-checked: var(--ion-color-secondary);
      }

      &:active,
      &:focus {
        ion-radio::part(container) {
          border-color: #bbb !important;
        }
      }
    }
  }

  &.full {
    ion-col {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.tertiary {
    ion-item.radio-wrapper {
      &.item-radio-checked {
        border-color: var(--ion-color-tertiary);

        &::part(native) {
          background: rgba(var(--ion-color-tertiary-rgb), 0.02);
        }
      }

      ion-radio {
        --color-checked: var(--ion-color-tertiary);
      }
    }
  }

  &.size-4 {
    ion-col {
      padding-left: 3px;
      padding-right: 3px;
    }

    ion-item.radio-wrapper {
      height: 30px;

      .radio-label {
        padding-left: 25px;
        font-size: 10px;
      }

      ion-radio {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.no-cols {
    ion-item.radio-wrapper {
      flex: none !important;
      display: inline-block;
      width: auto !important;
      max-width: auto !important;

      .radio-label {
        position: relative;
        padding-left: 10px;
        margin-top: 1px;
      }
    }

    &.size-4 ion-item.radio-wrapper {
      display: inline-flex;

      .radio-label {
        margin-top: -1px;
        padding-left: 3px;
      }

      ion-radio {
        margin-left: 0;
        margin-right: 3px;
      }
    }
  }
}

.rtl {
  .radio {
    ion-radio-group ion-item.radio-wrapper {
      .radio-label {
        padding-left: inherit;
        padding-right: 35px;
      }

      ion-radio {
        margin-left: inherit;
      }
    }

    &.size-4 {
      ion-item.radio-wrapper {
        .radio-label {
          padding-left: inherit;
          padding-right: 25px;
        }
      }
    }

    &.no-cols {
      ion-item.radio-wrapper .radio-label {
        padding-left: inherit;
        padding-right: 10px;
      }

      &.size-4 ion-item.radio-wrapper {
        .radio-label {
          padding-left: inherit;
          padding-right: 3px;
        }

        ion-radio {
          margin-right: 0;
          margin-left: 3px;
        }
      }
    }
  }
}
