.view-cart {
  .btn{
    border : #2ecc71;
    background : #2ecc71;
  }
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  animation: show 0.5s;
  z-index: 5;

  .sides {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    display: flex;
    padding: 0 20px;
    align-items: center;
    margin-top: 2px;
    opacity: 1 !important;

    &.cart {
      left: 0;
      margin-top: 3px;
      font-size: 18px;

      ion-icon {
        margin-top: -11px;
        margin-left: 4px;
        font-size: 21px;
      }
    }

    &.price {
      right: 0;
      font-size: 16px;
      span {
        font-weight: 600;
        margin-right: 5px;
        display: inline-block;
      }
    }
  }
}

.item.next-exit,
.item.next-enter {
  .view-cart {
    display: none;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rtl {
  .view-cart {
    left: inherit;
    right: 0;

    .sides {
      &.cart {
        left: inherit;
        right: 0;

        ion-icon {
          margin-left: inherit;
          margin-right: 4px;
        }
      }

      &.price {
        right: inherit;
        left: 0;
        span {
          margin-right: inherit;
          margin-left: 5px;
        }
      }
    }
  }
}
