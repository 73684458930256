.home-page {
  @media (min-width: 768px) {
    .container, .container-md, .container-sm {
      // max-width: 720px;
      margin:0px auto !important;
    }
  }
  .ion-padding{
    padding:15px;
  }
  ion-content {
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    // --padding-top: 0px !important;
    --padding-bottom: 85px;

    .category-list {
      &.icons {
        margin-top: 20px;
      }

      &.images {
        margin-bottom: 25px;
      }
    }

    .shop-banner{
      margin-bottom: 20px;
    }

    .product-list{
      // background: #f9f9f9;
      padding: 15px;
    }

    .product-item{
      border: 1px solid #ddd;
      box-shadow: 0 0 15px #ccc;
      border-radius: 10px !important;
      margin-bottom: 10px;
      .info{
        height: auto;
      }
      h3{
        margin:0;
        padding:5px 0;
      }
    }

    .nblock-section-products{
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 15px 0px;
    }
    .block-product-item {
      flex: 0 0 auto;
      /* padding: 10px; */
      margin-left: 10px;
      width: 170px;
    }
    .nblock-section-title{
      padding:15px;
      font-size: 16px;
      padding-bottom: 2px;
      font-weight: 600;
    }

    .categories-container{
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding:0 0;
    }
    .categories-item {
      flex: 0 0 auto;
      /* padding: 10px; */
      margin-left: 10px;
    }
    .categories-item img{
      width: 100px;
    }
    .categories-item .cat-title{
      padding-top:4px;
      // font-weight: 700;
    }

    .request-products {
      color: #333;
      border-color: #ccc;
      background-color: rgb(248, 248, 248);
    }
  }

  h3 {
    margin-top: 30px;
  }

  .shop-list{
    .shop-card{
      padding:10px; 
      width: 100%;;
      border:1px solid #eee;
      border-radius: 3px;
      margin-bottom: 10px;;
    }

    .shop-img{
      width:100px;
      height:100px;
      border-radius: 100px;
    }
  }

  .category-list {
    ion-row {
      ion-col {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;

        .img {
          ion-img {
            width: 55px;
            margin: 7px auto;
          }
        }
      }
    }

    &.icons ion-row ion-col .img {
      background: #fff;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.07);
      position: relative;
      margin: auto;
      margin-bottom: 8px;

      ion-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 33px;
        width: 100%;
      }
    }
  }
}
