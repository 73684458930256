.view-img {
  position: relative;
  z-index: 9999;

  ion-header {
    --background: transparent;
    z-index: 9999;
  }

  ion-toolbar {
    --background: transparent;
    --border-color: transparent;
    --ion-toolbar-color: white;
    position: relative;

    ion-button {
      font-size: 17px;
      font-weight: bold;
      background: none;
      outline: 0;
    }
  }

  .content {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .image-loader {
      width: 100%;
    }
  }
}
