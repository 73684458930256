.modal {
  .dialog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 35px 0px;
    background: #fff;
    box-shadow: none;
    min-height: 60px;
    z-index: 12;

    &.open {
      transform: translateY(0);
      transition: all 0.2s ease;
    }

    &.close {
      transform: translateY(600px);
      transition: all 0.2s ease;
    }

    > .title {
      margin-top: 0;
      margin-bottom: 25px;
      text-align: center;
    }

    ion-icon.close {
      padding: 6px;
      position: absolute;
      z-index: 2;
      top: 20px;
      font-size: 20px;
    }
  }

  &.raduis {
    .dialog {
      border-radius: 50px 50px 0 0;
    }
  }

  ion-backdrop {
    opacity: 0.7;
    outline: 0;
    z-index: 10;
    visibility: visible;
    transition: opacity 0.2s linear;

    &.close {
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s 0.2s, opacity 0.2s linear;
    }
  }
}
