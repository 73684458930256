.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .input{
    margin-bottom: 20px;
  }
  .input input {
    border-radius: 5px;
    height: 45px;
    
  }

  .btn-later{
    background: #fff;
    color: #999;
    margin-top: 10px;
    border: 1px solid #eee;
  }
  .swiper-pagination-bullets{
    display: none !important;
  }
  ion-slides {
    height: 100%;
    direction: ltr;
    

    ion-slide {
      display: block;

      .image-loader {
        img {
          width: 70%;
          margin: 10% auto 0;
        }
      }

      p {
        margin-top: 0;
      }
    }

    .swiper-pagination {
      position: fixed;
      bottom: 22px;
    }
  }

  .header {
    height: 60px;
    padding: 15px 20px;

    img {
      width: 110px;
    }

    button {
      float: right;
      font-size: 16px;
      padding-right: 5px;
      padding-left: 5px;
      font-weight: bold;
    }
    .later button {
      background:#eee !important;
      border-color:#eee !important;
      color:#333;
    }
  }

  ion-content {
    .content {
      text-align: center;
      padding-top: 34%;
      padding-right:20px;
      padding-left:20px;
      display: block;
      padding-bottom: 13px;
    }

    .content .img img{
      width:130px;
    }
    .content .title{
      padding:20px 0;
      font-size:24px;
    }
    .content .description{
      font-weight: 400;
      color: #333;
      padding-bottom:20px;
    }
    .content .button-container{

    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 15px 20px;
      z-index: 11;

      button:last-child {
        float: right;
      }
    }
  }
}

.rtl {
  .welcome-page {
    .header {
      button {
        float: left;
      }
    }

    button {
      ion-icon {
        transform: rotate(-180deg);
      }
    }

    .footer {
      button {
        ion-icon {
          transform: rotate(-180deg);
        }
      }

      button:last-child {
        float: left;
      }
    }
  }
}

@media (max-width: 345px) {
  .welcome-page ion-content .content .swiper-pagination {
    display: none;
  }
}
