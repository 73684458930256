.location-page {
  > ion-content {
    padding-top: 50px;
    padding-bottom: 0;
    background: var(--ion-color-grey);
    --background: var(--ion-color-grey);
    --padding-top: 35px;
  }

  h3 {
    font-size: 23px;
    margin-bottom: 15px;

    ion-text {
      font-size: 18px;
    }
  }

  .locations {
    .item {
      padding: 15px;
      border-radius: 30px;
      background: #fff;
      position: relative;
      height: 135px;
      overflow: hidden;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      text-decoration: none;

      > div {
        display: flex;
        text-decoration: none;
        align-items: center;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: -56px;
        top: -55px;
        width: 166px;
        height: 179px;
        background-color: #34b6b5;
        background-image: url('/assets/images/bubbles.png');
        background-size: 87px;
        background-size: cover;
        border-radius: 45px;
        transform: rotate(41deg);
        z-index: 0;
      }

      &.add-location {
        &::before {
          background-color: rgba(3, 3, 4, 0.19);
        }

        .title {
          font-size: 19px;
          color: #999;
          display: flex;
          align-items: center;
          align-content: center;
          font-weight: 500;

          ion-icon {
            background: var(--ion-color-grey);
            border-radius: 50%;
            padding: 5px;
            display: block;
            font-size: 25px;
            margin-right: 8px;
          }

          .text {
            margin-top: 8px;
          }
        }
      }

      h2 {
        z-index: 1;
        position: relative;
        display: inline-block;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 0px;
        margin-top: 3px;
      }

      .attrs {
        font-size: 11px;
        color: #777;

        p {
          margin: 0;
          padding: 0;
          line-height: 15px;
        }
      }

      .options {
        position: absolute;
        right: 20px;
        top: 15px;

        button {
          $size: 22px;
          padding: 0;
          line-height: 6px;
          height: $size;
          min-width: $size;
          border-radius: $size;
          display: block;
          margin: 0 5px;
          float: left;
          background: var(--ion-color-grey);
          color: #777;
          font-size: 13px;
          outline: 0;

          &.text {
            padding: 3px 13px 0;
            font-size: 10px;

            &.default {
              color: #fff;
              background: var(--ion-color-secondary);
            }
          }
        }
      }

      .icon {
        font-size: 100px;
        display: inline-block;
        margin-right: 20px;
        margin-left: 5px;
        position: relative;
        z-index: 1;
      }
    }
  }
}

@media (max-width: 400px) {
  .location-page .locations {
    .attrs {
      p {
        font-size: 10px;
      }
    }
    .item.add-location .title {
      font-size: 16px;

      ion-icon {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 325px) {
  .location-page {
    h3 {
      font-size: 19px;

      ion-text {
        font-size: 16px;
      }
    }

    .locations {
      .item {
        height: 120px;

        &:before {
          left: -56px;
          top: -55px;
          width: 141px;
          height: 154px;
          background-size: 87px;
          background-size: cover;
          border-radius: 45px;
          transform: rotate(41deg);
        }

        h2 {
          font-size: 18px;
        }

        .icon {
          font-size: 70px;
        }

        &.add-location .title {
          font-size: 15px;

          ion-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.rtl {
  .location-page .locations .item {
    &:before {
      left: initial;
      right: -56px;
      transform: rotate(141deg);
    }

    &.add-location .title ion-icon {
      margin-right: initial;
      margin-left: 8px;
    }

    .options {
      right: initial;
      left: 20px;

      button {
        float: right;
      }
    }

    .icon {
      margin-right: 5px;
      margin-left: 15px;
    }
  }
}
