.categories-page {
  ion-content {
    --padding-start: 0px; 
    --padding-end: 0px;

    ion-grid.d{
      padding:0px;

      ion-col{
        padding:0px;
      }
    }

    .category-list {
      &.icons {
        margin-top: 20px;
      }

      &.images {
        margin-bottom: 25px;
      }
    }

    .request-products {
      color: #333;
      border-color: #ccc;
      background-color: rgb(248, 248, 248);
    }
  }

  .categories-walls{
    padding:15px;
  }
  .parent-category-banner{
    padding-bottom: 10px;
  }
  .category-icon-item{
    padding:10px;
  }

  .category-item{
    width:100%;
    height:50px;
    font-size: 12px;
    font-weight: normal;
    border-bottom: 1px solid #eee;
    padding:8px 8px;
    background-color: #f8f8f8;
    display: table;
    // line-height: 50px;
  }
  .category-item.active{
    background-color: #fff;
    // color:#ed93a9;
  }
  .category-item span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    display: table-cell;
  vertical-align: middle;
  }
  .category-icon-item{
    text-align: center;
    padding-bottom:15px;
  }
  .category-icon-item img{
    width:100%;
    min-width: 60px;
    max-width: 64px;
  }
  .category-icon-item .title{
    padding-top:8px;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    line-height: 15px;
    overflow-wrap: initial;
  }

  h3 {
    margin-top: 30px;
  }

  .category-list {
    ion-row {
      ion-col {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;

        .img {
          ion-img {
            width: 55px;
            margin: 7px auto;
          }
        }
      }
    }

    &.icons ion-row ion-col .img {
      background: #fff;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.07);
      position: relative;
      margin: auto;
      margin-bottom: 8px;

      ion-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 33px;
        width: 100%;
      }
    }
  }
}
