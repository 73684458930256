.my-page {
  .modal-lang-conatiner{
    padding: 20px;
    padding-bottom: 40px;
  }

  


  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --background-activated: transparent;

    ion-icon {
      padding: 6px;
      font-size: 20px;
      background-color: rgb(242, 242, 242);
      border-radius: 10px;
      color: #666;
    }

    ion-label {
      padding-top: 7px;
      color: #444 !important;
    }
  }
}

.delete-page-modal{
  .btn-danger{
    background: #e74c3c !important;
    font-size: 14px !important;
    margin: 0 10px;
    height: 40px;
  }

  .btn-default{
    background: #f2f2f2 !important;
    color:#333 !important;
    font-size: 14px !important;
    height: 40px;
  }
}